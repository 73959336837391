module Main exposing (..)

import Browser
import Browser.Events
import Browser.Navigation
import Date
import Dict exposing (Dict)
import Dict.Any as AnyDict exposing (AnyDict)
import GoogleTagManager
import Html exposing (..)
import Html.Attributes exposing (..)
import Html.Events exposing (..)
import Icon
import Json.Decode as Decode exposing (Decoder)
import List.Zipper as Zipper exposing (Zipper)
import Logo
import Prescription exposing (Prescription)
import Query
import RemoteData exposing (RemoteData)
import Server
import Set.Any as AnySet exposing (AnySet)
import UI
import UnionSelect
import Url
import Util
import Validate



-- CONSTANTS


maxDrugNameSuggestions : Int
maxDrugNameSuggestions =
    8


drugNameLeadingCharsLen : Int
drugNameLeadingCharsLen =
    3


pharmacySearchRadii : List Int
pharmacySearchRadii =
    [ 3, 5, 10, 15, 20, 25, 30, 50, 100 ]


dateOfBirthMonths : List ( String, String )
dateOfBirthMonths =
    [ ( "Jan", "01" )
    , ( "Feb", "02" )
    , ( "Mar", "03" )
    , ( "Apr", "04" )
    , ( "May", "05" )
    , ( "Jun", "06" )
    , ( "Jul", "07" )
    , ( "Aug", "08" )
    , ( "Sept", "09" )
    , ( "Oct", "10" )
    , ( "Nov", "11" )
    , ( "Dec", "12" )
    ]


dateOfBirthDays : List String
dateOfBirthDays =
    List.range 1 31
        |> List.map String.fromInt
        |> List.map (String.padLeft 2 '0')


dateOfBirthYears : List String
dateOfBirthYears =
    List.range 1900 2024
        |> List.map String.fromInt
        |> List.reverse


states : List ( String, String )
states =
    [ ( "Alabama", "AL" )
    , ( "Alaska", "AK" )
    , ( "Arizona", "AZ" )
    , ( "Arkansas", "AR" )
    , ( "California", "CA" )
    , ( "Colorado", "CO" )
    , ( "Connecticut", "CT" )
    , ( "Delaware", "DE" )
    , ( "District of Columbia", "DC" )
    , ( "Florida", "FL" )
    , ( "Georgia", "GA" )
    , ( "Hawaii", "HI" )
    , ( "Idaho", "ID" )
    , ( "Illinois", "IL" )
    , ( "Indiana", "IN" )
    , ( "Iowa", "IA" )
    , ( "Kansas", "KS" )
    , ( "Kentucky", "KY" )
    , ( "Louisiana", "LA" )
    , ( "Maine", "ME" )
    , ( "Maryland", "MD" )
    , ( "Massachusetts", "MA" )
    , ( "Michigan", "MI" )
    , ( "Minnesota", "MN" )
    , ( "Mississippi", "MS" )
    , ( "Missouri", "MO" )
    , ( "Montana", "MT" )
    , ( "Nebraska", "NE" )
    , ( "Nevada", "NV" )
    , ( "New Hampshire", "NH" )
    , ( "New Jersey", "NJ" )
    , ( "New Mexico", "NM" )
    , ( "New York", "NY" )
    , ( "North Carolina", "NC" )
    , ( "North Dakota", "ND" )
    , ( "Ohio", "OH" )
    , ( "Oklahoma", "OK" )
    , ( "Oregon", "OR" )
    , ( "Pennsylvania", "PA" )
    , ( "Rhode Island", "RI" )
    , ( "South Carolina", "SC" )
    , ( "South Dakota", "SD" )
    , ( "Tennessee", "TN" )
    , ( "Texas", "TX" )
    , ( "Utah", "UT" )
    , ( "Vermont", "VT" )
    , ( "Virginia", "VA" )
    , ( "Washington", "WA" )
    , ( "West Virginia", "WV" )
    , ( "Wisconsin", "WI" )
    , ( "Wyoming", "WY" )
    ]



-- MAIN


main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = UrlRequested
        , onUrlChange = UrlChanged
        }


type alias Model =
    { key : Browser.Navigation.Key
    , nextId : Int
    , page : Page
    , userId : Maybe Int
    , agentFirstName : Maybe String
    , agentLastName : Maybe String
    , firstName : String
    , lastName : String
    , emailAddress : String
    , phoneNumber : String
    , phoneExtension : String
    , zipCode : String
    , countyFipsCode : String
    , dateOfBirthMonth : String
    , dateOfBirthDay : String
    , dateOfBirthYear : String
    , address1 : String
    , address2 : String
    , city : String
    , stateCode : String
    , counties : RemoteData Server.Error (List Server.County)
    , validatingProfileFields : AnySet Int ProfileField
    , validatingAddProfileFields : AnySet Int AdditionalProfileField
    , drugNameSearch : String
    , drugNameSearchHasFocus : Bool
    , drugNameSearchResults : RemoteData Server.Error (List String)
    , newPrescription : RemoteData Server.Error Prescription
    , prescriptions : List Prescription
    , pharmacySearchZip : String
    , pharmacySearchRadius : String
    , pharmacySearchHasFocus : Bool
    , pharmacySearchResults : RemoteData Server.Error (List Server.Pharmacy)
    , pharmacies : List Server.Pharmacy
    , submission : RemoteData Server.Error ()
    }


type Page
    = Profile
    | Prescriptions
    | Pharmacies
    | AdditionalInfo
    | Summary


type ProfileField
    = FirstName
    | LastName
    | EmailAddress
    | PhoneNumber
    | PhoneExtension
    | ZipCode
    | CountyCode


type AdditionalProfileField
    = DateOfBirthMonth
    | DateOfBirthDay
    | DateOfBirthYear
    | Address1
    | Address2
    | City
    | State


type alias Flags =
    {}


init : Flags -> Url.Url -> Browser.Navigation.Key -> ( Model, Cmd Msg )
init _ url key =
    let
        queryParameters =
            Query.parse url

        userId =
            Query.int "userid" queryParameters

        agentFirstName =
            Query.string "guidefname" queryParameters

        agentLastName =
            Query.string "guidelname" queryParameters

        firstName =
            Query.string "fname" queryParameters |> Maybe.withDefault ""

        lastName =
            Query.string "lname" queryParameters |> Maybe.withDefault ""

        emailAddress =
            Query.string "email" queryParameters |> Maybe.withDefault ""

        phoneNumber =
            Query.string "phone" queryParameters |> Maybe.withDefault ""

        dateOfBirth =
            Query.date "dob" queryParameters

        dateOfBirthMonth =
            dateOfBirth
                |> Maybe.map (Date.month >> Date.monthToNumber >> String.fromInt >> String.padLeft 2 '0')
                |> Maybe.withDefault ""

        dateOfBirthDay =
            dateOfBirth
                |> Maybe.map (Date.day >> String.fromInt >> String.padLeft 2 '0')
                |> Maybe.withDefault ""

        dateOfBirthYear =
            dateOfBirth
                |> Maybe.map (Date.year >> String.fromInt)
                |> Maybe.withDefault ""

        address1 =
            Query.string "add" queryParameters |> Maybe.withDefault ""

        address2 =
            Query.string "add2" queryParameters |> Maybe.withDefault ""

        city =
            Query.string "city" queryParameters |> Maybe.withDefault ""

        stateCode =
            Query.string "state" queryParameters |> Maybe.withDefault ""

        zipCode =
            Query.string "zip" queryParameters |> Maybe.withDefault ""

        ( counties, countiesLookupCmd ) =
            if Validate.isZipCode zipCode then
                ( RemoteData.Loading
                , Server.rpcService.listCountiesByZipCode zipCode
                    |> Server.send CompletedListCountiesByZipCode
                )

            else
                ( RemoteData.NotAsked
                , Cmd.none
                )

        model =
            { key = key
            , nextId = 1
            , page = Profile
            , userId = userId
            , agentFirstName = agentFirstName
            , agentLastName = agentLastName
            , firstName = firstName
            , lastName = lastName
            , emailAddress = emailAddress
            , phoneNumber = phoneNumber
            , phoneExtension = ""
            , zipCode = zipCode
            , countyFipsCode = ""
            , dateOfBirthMonth = dateOfBirthMonth
            , dateOfBirthDay = dateOfBirthDay
            , dateOfBirthYear = dateOfBirthYear
            , address1 = address1
            , address2 = address2
            , city = city
            , stateCode = stateCode
            , counties = counties
            , validatingProfileFields = AnySet.empty profileFieldToInt
            , validatingAddProfileFields = AnySet.empty additionalProfileFieldToInt
            , drugNameSearch = ""
            , drugNameSearchHasFocus = False
            , drugNameSearchResults = RemoteData.NotAsked
            , newPrescription = RemoteData.NotAsked
            , prescriptions = []
            , pharmacySearchZip = ""
            , pharmacySearchRadius = "5"
            , pharmacySearchHasFocus = False
            , pharmacySearchResults = RemoteData.NotAsked
            , pharmacies = []
            , submission = RemoteData.NotAsked
            }

        pushStepChangeCmd =
            if hasRequiredQueryParams model then
                GoogleTagManager.pushStepChanged
                    { step = 1
                    , prevStep = 0
                    , userId = model.userId |> Maybe.withDefault 0
                    }

            else
                Cmd.none

        logQueryParamsError =
            if hasRequiredQueryParams model then
                Cmd.none

            else
                Server.rpcService.logClientError ("invalid query string: required parameters missing: " ++ Url.toString url)
                    |> Server.send (always NoOp)
    in
    ( model
    , Cmd.batch
        [ pushStepChangeCmd
        , logQueryParamsError
        , countiesLookupCmd
        ]
    )


type Msg
    = BlurredEmailAddress
    | BlurredFirstName
    | BlurredLastName
    | BlurredPhoneExtension
    | BlurredPhoneNumber
    | BlurredZipCode
    | ChangedAddress1 String
    | ChangedAddress2 String
    | ChangedCity String
    | ChangedCountyCode String
    | ChangedDateOfBirthDate String
    | ChangedDateOfBirthMonth String
    | ChangedDateOfBirthYear String
    | ChangedDrugNameSearch String
    | ChangedFirstName String
    | ChangedLastName String
    | ChangedEmailAddress String
    | ChangedPharmacySearchRadius String
    | ChangedPharmacySearchZip String
    | ChangedPhoneExtension String
    | ChangedPhoneNumber String
    | ChangedState String
    | ChangedZipCode String
    | ClickedAddPrescription
    | ClickedCancelPrescription
    | ClickedDeletePharmacy String
    | ClickedDeletePrescription Int
    | ClickedDocument
    | ClickedDrugName String
    | ClickedGoToPage Page
    | ClickedPharmacy Server.Pharmacy
    | ClickedSubmit
    | CompletedCreateUser (Result Server.Error ())
    | CompletedListCountiesByZipCode (Result Server.Error (List Server.County))
    | CompletedListDrugDosagesByDrugName String (Result Server.Error (List Server.Drug))
    | CompletedListPharmaciesByZipCode (Result Server.Error (List Server.Pharmacy))
    | CompletedSuggestDrugNames String (Result Server.Error (List String))
    | FocusedDrugNameSearch
    | FocusedPharmacySearchZip
    | GotPrescriptionMsg Prescription.Msg
    | NoOp
    | SubmittedAdditionalProfileForm
    | SubmittedProfileForm
    | UrlChanged Url.Url
    | UrlRequested Browser.UrlRequest


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        pair =
            updateHelp msg model

        newModel =
            Tuple.first pair

        page =
            newModel.page

        prevPage =
            model.page

        pageToStep p =
            case p of
                Profile ->
                    1

                Prescriptions ->
                    2

                Pharmacies ->
                    3

                AdditionalInfo ->
                    4

                Summary ->
                    5
    in
    if page /= prevPage then
        Tuple.mapSecond
            (\cmd ->
                Cmd.batch
                    [ cmd
                    , GoogleTagManager.pushStepChanged
                        { step = pageToStep page
                        , prevStep = pageToStep prevPage
                        , userId = newModel.userId |> Maybe.withDefault 0
                        }
                    ]
            )
            pair

    else
        pair


updateHelp : Msg -> Model -> ( Model, Cmd Msg )
updateHelp msg model =
    case msg of
        BlurredEmailAddress ->
            ( { model | validatingProfileFields = AnySet.insert EmailAddress model.validatingProfileFields }
            , Cmd.none
            )

        BlurredFirstName ->
            ( { model | validatingProfileFields = AnySet.insert FirstName model.validatingProfileFields }
            , Cmd.none
            )

        BlurredLastName ->
            ( { model | validatingProfileFields = AnySet.insert LastName model.validatingProfileFields }
            , Cmd.none
            )

        BlurredPhoneExtension ->
            ( { model | validatingProfileFields = AnySet.insert PhoneExtension model.validatingProfileFields }
            , Cmd.none
            )

        BlurredPhoneNumber ->
            ( { model | validatingProfileFields = AnySet.insert PhoneNumber model.validatingProfileFields }
            , Cmd.none
            )

        BlurredZipCode ->
            ( { model | validatingProfileFields = AnySet.insert ZipCode model.validatingProfileFields }
            , Cmd.none
            )

        ChangedAddress1 address1 ->
            ( { model | address1 = address1 }
            , Cmd.none
            )

        ChangedAddress2 address2 ->
            ( { model | address2 = address2 }
            , Cmd.none
            )

        ChangedCity city ->
            ( { model | city = city }
            , Cmd.none
            )

        ChangedCountyCode countyCode ->
            ( { model | countyFipsCode = countyCode }
            , Cmd.none
            )

        ChangedDateOfBirthDate day ->
            ( { model | dateOfBirthDay = day }
            , Cmd.none
            )

        ChangedDateOfBirthMonth month ->
            ( { model | dateOfBirthMonth = month }
            , Cmd.none
            )

        ChangedDateOfBirthYear year ->
            ( { model | dateOfBirthYear = year }
            , Cmd.none
            )

        ChangedDrugNameSearch drugNameSearch ->
            let
                prevDnChars =
                    getDrugNameLeadingChars model.drugNameSearch

                dnChars =
                    getDrugNameLeadingChars drugNameSearch
            in
            if String.length dnChars < drugNameLeadingCharsLen then
                ( { model | drugNameSearch = drugNameSearch, drugNameSearchResults = RemoteData.NotAsked }
                , Cmd.none
                )

            else if String.length dnChars == drugNameLeadingCharsLen && dnChars /= prevDnChars then
                ( { model | drugNameSearch = drugNameSearch, drugNameSearchResults = RemoteData.Loading }
                , Server.rpcService.suggestDrugNames dnChars
                    |> Server.send (CompletedSuggestDrugNames dnChars)
                )

            else if String.length dnChars == drugNameLeadingCharsLen && dnChars == prevDnChars then
                ( { model | drugNameSearch = drugNameSearch }
                , Cmd.none
                )

            else
                ( model
                , Cmd.none
                )

        ChangedFirstName firstName ->
            ( { model | firstName = firstName }
            , Cmd.none
            )

        ChangedLastName lastName ->
            ( { model | lastName = lastName }
            , Cmd.none
            )

        ChangedEmailAddress emailAddress ->
            ( { model | emailAddress = emailAddress }
            , Cmd.none
            )

        ChangedPharmacySearchRadius pharmacySearchRadius ->
            let
                isZipValid =
                    Validate.isZipCode model.pharmacySearchZip
            in
            if isZipValid then
                ( { model
                    | pharmacySearchRadius = pharmacySearchRadius
                    , pharmacySearchResults = RemoteData.Loading
                  }
                , Server.rpcService.listPharmaciesByZipCode model.pharmacySearchZip pharmacySearchRadius
                    |> Server.send CompletedListPharmaciesByZipCode
                )

            else
                ( { model | pharmacySearchRadius = pharmacySearchRadius }
                , Cmd.none
                )

        ChangedPharmacySearchZip zipCode ->
            let
                isZipValid =
                    Validate.isZipCode zipCode
            in
            if isZipValid then
                ( { model
                    | pharmacySearchZip = zipCode
                    , pharmacySearchResults = RemoteData.Loading
                  }
                , Server.rpcService.listPharmaciesByZipCode zipCode model.pharmacySearchRadius
                    |> Server.send CompletedListPharmaciesByZipCode
                )

            else
                ( { model
                    | pharmacySearchZip = zipCode
                    , pharmacySearchResults = RemoteData.NotAsked
                  }
                , Cmd.none
                )

        ChangedPhoneExtension phoneExtension ->
            ( { model | phoneExtension = phoneExtension }
            , Cmd.none
            )

        ChangedPhoneNumber phoneNumber ->
            ( { model | phoneNumber = phoneNumber }
            , Cmd.none
            )

        ChangedState state ->
            ( { model | stateCode = state }
            , Cmd.none
            )

        ChangedZipCode zipCode ->
            if Validate.isZipCode zipCode then
                ( { model
                    | zipCode = zipCode
                    , countyFipsCode = ""
                    , counties = RemoteData.Loading
                    , validatingProfileFields = AnySet.insert ZipCode model.validatingProfileFields
                  }
                , Server.rpcService.listCountiesByZipCode zipCode
                    |> Server.send CompletedListCountiesByZipCode
                )

            else
                ( { model
                    | zipCode = zipCode
                    , countyFipsCode = ""
                    , counties = RemoteData.NotAsked
                  }
                , Cmd.none
                )

        ClickedAddPrescription ->
            case model.newPrescription of
                RemoteData.Success prescription ->
                    ( { model
                        | prescriptions =
                            (prescription :: model.prescriptions)
                                |> List.sortBy (\p -> (Prescription.dosage p).drugName)
                        , newPrescription = RemoteData.NotAsked
                      }
                    , Cmd.none
                    )

                _ ->
                    ( model
                    , Cmd.none
                    )

        ClickedCancelPrescription ->
            ( { model | newPrescription = RemoteData.NotAsked }
            , Cmd.none
            )

        ClickedDeletePharmacy pharmacyId ->
            let
                pharmacies =
                    List.filter (\pharmacy -> pharmacy.pharmacyId /= pharmacyId) model.pharmacies
            in
            ( { model | pharmacies = pharmacies }
            , Cmd.none
            )

        ClickedDeletePrescription prescriptionId ->
            ( { model | prescriptions = List.filter (\prescription -> Prescription.id prescription /= prescriptionId) model.prescriptions }
            , Cmd.none
            )

        ClickedDocument ->
            ( { model
                | drugNameSearchHasFocus = False
                , pharmacySearchHasFocus = False
              }
            , Cmd.none
            )

        ClickedDrugName drugName ->
            ( { model
                | drugNameSearch = ""
                , drugNameSearchResults = RemoteData.NotAsked
                , drugNameSearchHasFocus = False
                , newPrescription = RemoteData.Loading
              }
            , Server.rpcService.listDrugDosagesByDrugName drugName
                |> Server.send (CompletedListDrugDosagesByDrugName drugName)
            )

        ClickedGoToPage page ->
            ( { model | page = page }
            , Cmd.none
            )

        ClickedPharmacy pharmacy ->
            ( { model
                | pharmacies = [ pharmacy ]
                , pharmacySearchHasFocus = False
              }
            , Cmd.none
            )

        ClickedSubmit ->
            let
                dateOfBirth =
                    [ model.dateOfBirthYear, model.dateOfBirthMonth, model.dateOfBirthDay ]
                        |> String.join "-"
            in
            ( { model | submission = RemoteData.Loading }
            , Server.rpcService.createUser
                { hubspotUserId = model.userId |> Maybe.withDefault 0
                , agentFirstName = model.agentFirstName |> Maybe.withDefault ""
                , agentLastName = model.agentLastName |> Maybe.withDefault ""
                , firstName = model.firstName
                , lastName = model.lastName
                , dateOfBirth = dateOfBirth
                , emailAddress = model.emailAddress
                , phoneNumber = model.phoneNumber
                , phoneExtension = model.phoneExtension
                , address1 = model.address1
                , address2 = model.address2
                , city = model.city
                , stateCode = model.stateCode
                , zipCode = model.zipCode
                , countyFipsCode = model.countyFipsCode
                , prescriptions =
                    List.map
                        (\p ->
                            { drug =
                                { dosageId = (Prescription.dosage p).dosageId
                                , packType = (Prescription.dosage p).packType
                                , unit = (Prescription.dosage p).unit
                                , ncdCode = (Prescription.dosage p).ncdCode
                                , commonMetricQuantity = (Prescription.dosage p).commonMetricQuantity
                                , strength = (Prescription.dosage p).strength
                                , tradeName = (Prescription.dosage p).tradeName
                                , drugName = (Prescription.dosage p).drugName
                                , drugId = (Prescription.dosage p).drugId
                                , isCommonDosage = (Prescription.dosage p).isCommonDosage
                                , drugType = (Prescription.dosage p).drugType
                                , genericDrugName = (Prescription.dosage p).genericDrugName
                                , packSize = (Prescription.dosage p).packSize
                                , genericDosageId = (Prescription.dosage p).genericDosageId
                                , genericDrugId = (Prescription.dosage p).genericDrugId
                                , commonDaysOfSupply = (Prescription.dosage p).commonDaysOfSupply
                                , commonUserQuantity = (Prescription.dosage p).commonUserQuantity
                                }
                            , drugBaseName = Prescription.drugName p
                            , quantity = Prescription.quantity p
                            , frequency =
                                case Prescription.frequency p of
                                    Prescription.OneMonth ->
                                        "30"

                                    Prescription.TwoMonths ->
                                        "60"

                                    Prescription.ThreeMonths ->
                                        "90"

                                    Prescription.SixMonths ->
                                        "180"

                                    Prescription.OneYear ->
                                        "365"
                            }
                        )
                        model.prescriptions
                , pharmacies = model.pharmacies
                }
                |> Server.withTimeout (Just 10000)
                |> Server.send CompletedCreateUser
            )

        CompletedCreateUser result ->
            ( { model | submission = RemoteData.fromResult result }
            , Cmd.none
            )

        CompletedListCountiesByZipCode result ->
            let
                firstCountyCode =
                    Result.toMaybe result
                        |> Maybe.andThen List.head
                        |> Maybe.map .fipsCode
                        |> Maybe.withDefault ""
            in
            ( { model
                | counties = RemoteData.fromResult result
                , countyFipsCode = firstCountyCode
              }
            , Cmd.none
            )

        CompletedListDrugDosagesByDrugName drugName (Ok dosages) ->
            ( { model
                | nextId = model.nextId + 1
                , newPrescription =
                    Prescription.new
                        { id = model.nextId
                        , drugName = drugName
                        , dosages = dosages
                        }
                        |> Maybe.map RemoteData.Success
                        |> Maybe.withDefault RemoteData.NotAsked
              }
            , Cmd.none
            )

        CompletedListDrugDosagesByDrugName drugName (Err e) ->
            ( { model | newPrescription = RemoteData.Failure e }
            , Cmd.none
            )

        CompletedListPharmaciesByZipCode results ->
            ( { model | pharmacySearchResults = RemoteData.fromResult results }
            , Cmd.none
            )

        CompletedSuggestDrugNames searchDrugNameChars result ->
            let
                modelDrugNameChars =
                    getDrugNameLeadingChars model.drugNameSearch
            in
            if modelDrugNameChars == searchDrugNameChars then
                ( { model | drugNameSearchResults = RemoteData.fromResult result }
                , Cmd.none
                )

            else
                ( model
                , Cmd.none
                )

        FocusedDrugNameSearch ->
            ( { model | drugNameSearchHasFocus = True }
            , Cmd.none
            )

        FocusedPharmacySearchZip ->
            ( { model | pharmacySearchHasFocus = True }
            , Cmd.none
            )

        GotPrescriptionMsg prescriptionMsg ->
            case model.newPrescription of
                RemoteData.Success prescription ->
                    ( { model | newPrescription = RemoteData.Success (Prescription.update prescriptionMsg prescription) }
                    , Cmd.none
                    )

                _ ->
                    ( model, Cmd.none )

        NoOp ->
            ( model, Cmd.none )

        SubmittedAdditionalProfileForm ->
            let
                validatingAddProfileFields =
                    model.validatingAddProfileFields
                        |> AnySet.insert DateOfBirthDay
                        |> AnySet.insert DateOfBirthMonth
                        |> AnySet.insert DateOfBirthYear
                        |> AnySet.insert Address1
                        |> AnySet.insert Address2
                        |> AnySet.insert City
                        |> AnySet.insert State
            in
            if isAdditionalProfileFormValid model then
                ( { model
                    | page = Summary
                    , validatingAddProfileFields = validatingAddProfileFields
                  }
                , Cmd.none
                )

            else
                ( { model | validatingAddProfileFields = validatingAddProfileFields }
                , Cmd.none
                )

        SubmittedProfileForm ->
            let
                validatingProfileFields =
                    model.validatingProfileFields
                        |> AnySet.insert FirstName
                        |> AnySet.insert LastName
                        |> AnySet.insert EmailAddress
                        |> AnySet.insert PhoneNumber
                        |> AnySet.insert PhoneExtension
                        |> AnySet.insert ZipCode
                        |> AnySet.insert CountyCode
            in
            if isProfileFormValid model then
                ( { model
                    | page = Prescriptions
                    , validatingProfileFields = validatingProfileFields
                  }
                , Cmd.none
                )

            else
                ( { model | validatingProfileFields = validatingProfileFields }
                , Cmd.none
                )

        UrlChanged url ->
            ( model
            , Cmd.none
            )

        UrlRequested (Browser.Internal url) ->
            ( model
            , Browser.Navigation.pushUrl model.key (Url.toString url)
            )

        UrlRequested (Browser.External url) ->
            if String.isEmpty (String.trim url) then
                ( model
                , Cmd.none
                )

            else
                ( model
                , Browser.Navigation.load url
                )


view : Model -> Browser.Document Msg
view model =
    { title = ""
    , body =
        [ div [ class "bg-white rounded sm:max-w-xl sm:mt-12 sm:shadow-md  mx-auto" ]
            [ div [ class "px-4 py-3 sm:px-8 bg-med-green rounded-t" ] [ Logo.view ]
            , div [ class "p-4 sm:p-8" ]
                (case ( hasRequiredQueryParams model, model.page ) of
                    ( True, Profile ) ->
                        viewProfilePage model

                    ( True, Prescriptions ) ->
                        viewPrescriptionsPage model

                    ( True, Pharmacies ) ->
                        viewPharmaciesPage model

                    ( True, AdditionalInfo ) ->
                        viewAdditionalProfilePage model

                    ( True, Summary ) ->
                        viewSummaryPage model

                    ( False, _ ) ->
                        viewNotFoundPage
                )
            ]
        ]
    }



-- VIEW/PAGE


viewProfilePage : Model -> List (Html Msg)
viewProfilePage model =
    let
        validationErrors =
            validateProfileForm model

        hasValidationError field =
            AnySet.member field model.validatingProfileFields && AnyDict.member field validationErrors

        validationError field =
            if AnySet.member field model.validatingProfileFields then
                AnyDict.get field validationErrors

            else
                Nothing

        isCountySelectDisabled =
            case model.counties of
                RemoteData.Success (_ :: _) ->
                    False

                _ ->
                    True
    in
    [ Html.form [ onSubmit SubmittedProfileForm ]
        [ viewPageHeader
            { stepNum = 1
            , heading = "Profile"
            , caption = "Enter your personal information so we know who you are."
            }
        , div [ class "mb-8 space-y-3" ]
            [ div [ class "grid grid-cols-2 gap-3" ]
                [ div [ class "col-span-2 sm:col-span-1 space-y-[6px]" ]
                    [ label [ class "block text-med-brown1", for "firstName" ] [ text "First name" ]
                    , input
                        [ id "firstName"
                        , classList
                            [ ( "w-full", True )
                            , ( "input-error", hasValidationError FirstName )
                            ]
                        , type_ "text"
                        , value model.firstName
                        , autocomplete "given-name"
                        , onInput ChangedFirstName
                        , onBlur BlurredFirstName
                        ]
                        []
                    , case validationError FirstName of
                        Just errMsg ->
                            div [ class "text-xs text-red-500" ] [ text errMsg ]

                        Nothing ->
                            text ""
                    ]
                , div [ class "col-span-2 sm:col-span-1 space-y-[6px]" ]
                    [ label [ class "block text-med-brown1", for "lastName" ] [ text "Last name" ]
                    , input
                        [ id "lastName"
                        , classList
                            [ ( "w-full", True )
                            , ( "input-error", hasValidationError LastName )
                            ]
                        , type_ "text"
                        , value model.lastName
                        , autocomplete "family-name"
                        , onInput ChangedLastName
                        , onBlur BlurredLastName
                        ]
                        []
                    , case validationError LastName of
                        Just errMsg ->
                            div [ class "text-xs text-red-500" ] [ text errMsg ]

                        Nothing ->
                            text ""
                    ]
                ]
            , div [ class "space-y-[6px]" ]
                [ label [ class "block text-med-brown1", for "emailAddress" ] [ text "Email address" ]
                , input
                    [ id "emailAddress"
                    , classList
                        [ ( "w-full", True )
                        , ( "input-error", hasValidationError EmailAddress )
                        ]
                    , type_ "text"
                    , value model.emailAddress
                    , autocomplete "email"
                    , onInput ChangedEmailAddress
                    , onBlur BlurredEmailAddress
                    ]
                    []
                , case validationError EmailAddress of
                    Just errMsg ->
                        div [ class "text-xs text-red-500" ] [ text errMsg ]

                    Nothing ->
                        text ""
                ]
            , div [ class "flex gap-3" ]
                [ div [ class "flex-1 space-y-[6px]" ]
                    [ label [ class "block text-med-brown1", for "phoneNumber" ] [ text "Phone number" ]
                    , input
                        [ id "phoneNumber"
                        , classList
                            [ ( "", True )
                            , ( "input-error", hasValidationError PhoneNumber )
                            ]
                        , type_ "text"
                        , value model.phoneNumber
                        , autocomplete "tel"
                        , onInput ChangedPhoneNumber
                        , onBlur BlurredPhoneNumber
                        ]
                        []
                    , case validationError PhoneNumber of
                        Just err ->
                            div [ class "text-xs text-red-500" ] [ text err ]

                        Nothing ->
                            text ""
                    ]
                , div [ class "space-y-[6px] w-40 hidden" ]
                    [ label [ class "block text-med-brown1", for "phoneExt" ] [ text "Ext" ]
                    , input
                        [ id "phoneExt"
                        , classList
                            [ ( "w-full", True )
                            , ( "input-error", hasValidationError PhoneExtension )
                            ]
                        , type_ "text"
                        , value model.phoneExtension
                        , autocomplete "tel-extension"
                        , onInput ChangedPhoneExtension
                        , onBlur BlurredPhoneExtension
                        ]
                        []
                    , case validationError PhoneExtension of
                        Just errMsg ->
                            div [ class "text-xs text-red-500" ] [ text errMsg ]

                        Nothing ->
                            text ""
                    ]
                ]
            , div [ class "space-y-[6px]" ]
                [ label [ class "block text-med-brown1", for "zipCode" ] [ text "Zip code" ]
                , input
                    [ id "zipCode"
                    , type_ "text"
                    , classList
                        [ ( "input-error", hasValidationError ZipCode )
                        ]
                    , value model.zipCode
                    , autocomplete "zip-code"
                    , onInput ChangedZipCode
                    , onBlur BlurredZipCode
                    ]
                    []
                , case validationError ZipCode of
                    Just errMsg ->
                        div [ class "text-xs text-red-500" ] [ text errMsg ]

                    Nothing ->
                        text ""
                ]
            , case model.counties of
                RemoteData.Success counties ->
                    if List.length counties > 1 then
                        div [ class "space-y-[6px]" ]
                            [ label [ class "block text-med-brown1", for "countyCode" ] [ text "County" ]
                            , select
                                [ id "countyCode"
                                , class "w-full"
                                , disabled isCountySelectDisabled
                                , onInput ChangedCountyCode
                                ]
                                (List.map
                                    (\county ->
                                        option
                                            [ value county.fipsCode
                                            , selected (county.fipsCode == model.countyFipsCode)
                                            ]
                                            [ text county.name ]
                                    )
                                    counties
                                )
                            ]

                    else
                        viewNothing

                _ ->
                    viewNothing
            ]
        , div [ class "flex justify-end" ]
            [ button
                [ type_ "submit"
                , classList
                    [ ( "rounded bg-med-green text-white font-raleway font-semibold flex items-center gap-2 py-2 px-4 transition", True )
                    , ( "opacity-50", not (isProfileFormValid model) )
                    , ( "hover:bg-[#009984]", isProfileFormValid model )
                    ]
                ]
                [ span [] [ text "Prescriptions" ]
                , Icon.default Icon.ArrowRight
                ]
            ]
        ]
    ]


viewPrescriptionsPage : Model -> List (Html Msg)
viewPrescriptionsPage model =
    let
        normalize =
            String.trim >> String.toLower

        normalizedDrugNameSearch =
            normalize model.drugNameSearch

        filteredDrugNameSearchResults =
            RemoteData.map
                (\drugNames ->
                    drugNames
                        |> List.filter (normalize >> String.contains normalizedDrugNameSearch)
                        |> List.take maxDrugNameSuggestions
                )
                model.drugNameSearchResults
    in
    [ -- Header
      viewPageHeader
        { stepNum = 2
        , heading = "Prescriptions"
        , caption = "Search for a drug by name to add it to your list of prescriptions."
        }

    -- Search
    , div [ class "mb-8 relative", stopPropagationOn "click" (Decode.succeed ( NoOp, True )) ]
        [ label [ for "drugNameSearch", class "block text-med-brown1" ] [ text "Search by drug name" ]
        , div [ class "relative mt-[6px]" ]
            [ div [ class "absolute z-20 inset-0 flex items-center justify-end pr-3 pointer-events-none text-med-brown1" ] [ Icon.default Icon.SearchSm ]
            , input
                [ id "drugNameSearch"
                , class "z-10 w-full pr-10"
                , type_ "text"
                , value model.drugNameSearch
                , onInput ChangedDrugNameSearch
                , onFocus FocusedDrugNameSearch
                ]
                []
            ]

        -- Search results
        , case ( filteredDrugNameSearchResults, model.drugNameSearchHasFocus ) of
            ( RemoteData.Loading, True ) ->
                div [ class "border border-stone-300 rounded py-2 shadow top-full mt-1 absolute top-full mt-1 bg-white w-full z-50 py-2 px-4 text-stone-300" ]
                    [ Icon.default Icon.Loading4Animated ]

            ( RemoteData.Success [], True ) ->
                div [ class "border border-stone-300 rounded py-2 shadow top-full mt-1 absolute top-full mt-1 bg-white w-full z-50 py-2 px-4 text-stone-500 text-sm italic" ]
                    [ text "No results found" ]

            ( RemoteData.Success drugNames, True ) ->
                div
                    [ class "border border-stone-300 rounded py-2 shadow top-full mt-2 absolute top-full mt-1 bg-white w-full z-50"
                    ]
                    (List.map
                        (\drugName ->
                            div [ class "text-stone-500 py-2 px-4 hover:bg-stone-100 cursor-pointer", onClick (ClickedDrugName drugName) ] [ text drugName ]
                        )
                        drugNames
                    )

            _ ->
                text ""
        ]

    -- New prescription
    , case model.newPrescription of
        RemoteData.Loading ->
            div [ class "mb-8" ] [ Prescription.viewEditorLoading ]

        RemoteData.Success prescription ->
            div [ class "mb-8" ]
                [ Prescription.viewEditor
                    { prescription = prescription
                    , existingNcdCodes = List.map (\p -> Prescription.dosage p |> .ncdCode) model.prescriptions
                    , onAdd = ClickedAddPrescription
                    , onCancel = ClickedCancelPrescription
                    , toMsg = GotPrescriptionMsg
                    }
                ]

        _ ->
            text ""

    -- Your prescriptions
    , div [ class "mb-8" ]
        [ div [ class "font-domine font-bold text-med-brown1 mb-3" ] [ text "Your prescriptions" ]
        , if List.isEmpty model.prescriptions then
            div [ class "border border-stone-300 rounded p-12 text-stone-400 text-center mt-4" ]
                [ div [ class "font-medium" ] [ text "You have no prescriptions." ]
                , div [ class "text-sm" ] [ text "Add your first prescription by typing a drug name into search box above." ]
                ]

          else
            div [ class "space-y-1" ] (List.map (\prescription -> Prescription.viewCard ClickedDeletePrescription prescription) model.prescriptions)
        ]

    -- Prev/Next
    , div [ class "flex" ]
        [ UI.viewSecondaryButton [ type_ "button", onClick (ClickedGoToPage Profile) ]
            [ Icon.default Icon.ArrowLeft
            , span [] [ text "Profile" ]
            ]
        , UI.viewPrimaryButton
            [ class "ml-auto"
            , type_ "button"
            , onClick (ClickedGoToPage Pharmacies)
            , disabled (List.isEmpty model.prescriptions)
            ]
            [ span [] [ text "Pharmacies" ]
            , Icon.default Icon.ArrowRight
            ]
        ]
    ]


viewPharmaciesPage : Model -> List (Html Msg)
viewPharmaciesPage model =
    [ -- Header
      viewPageHeader
        { stepNum = 3
        , heading = "Pharmacy"
        , caption = "Search for your primary pharmacy by zip code."
        }

    -- Search
    , div [ class "mb-8 relative", stopPropagationOn "click" (Decode.succeed ( NoOp, True )) ]
        [ label [ for "pharmacySearch", class "block mb-[6px] text-med-brown1" ] [ text "Search by zip code" ]
        , div [ class "flex gap-3" ]
            [ div [ class "flex-none relative" ]
                [ div [ class "absolute z-20 inset-0 flex items-center justify-end pr-3 pointer-events-none text-med-brown1" ] [ Icon.default Icon.SearchSm ]
                , input
                    [ id "pharmacySearch"
                    , class "z-10 w-full"
                    , type_ "text"
                    , value model.pharmacySearchZip
                    , onInput ChangedPharmacySearchZip
                    , onFocus FocusedPharmacySearchZip
                    ]
                    []
                ]
            , select [ onInput ChangedPharmacySearchRadius, class "flex-1" ]
                (List.map (\rad -> option [ value (String.fromInt rad) ] [ text ("within " ++ String.fromInt rad ++ " miles") ]) pharmacySearchRadii)
            ]

        -- Search results
        , case ( model.pharmacySearchResults, model.pharmacySearchHasFocus ) of
            ( RemoteData.Loading, True ) ->
                div [ class "border border-stone-300 rounded py-2 shadow top-full mt-1 absolute top-full mt-1 bg-white w-full z-50 py-2 px-4 text-stone-300" ]
                    [ Icon.default Icon.Loading4Animated ]

            ( RemoteData.Success [], True ) ->
                div [ class "border border-stone-300 rounded py-2 shadow top-full mt-1 absolute top-full mt-1 bg-white w-full z-50 py-2 px-4 text-stone-500 text-sm italic" ]
                    [ text "No results found" ]

            ( RemoteData.Success pharmacies, True ) ->
                div
                    [ class "border border-stone-300 rounded py-2 shadow top-full mt-2 absolute top-full mt-1 bg-white w-full z-50 max-h-96 overflow-y-auto"
                    ]
                    (List.map
                        (\pharmacy ->
                            div [ class "text-stone-500 py-2 px-4 hover:bg-stone-100 cursor-pointer", onClick (ClickedPharmacy pharmacy) ]
                                [ div [] [ text pharmacy.name ]
                                , div [ class "text-xs" ] [ text (pharmacyAddress pharmacy) ]
                                ]
                        )
                        pharmacies
                    )

            _ ->
                text ""
        ]

    -- Your pharmacies
    , div [ class "mb-8" ]
        [ div [ class "font-domine font-bold text-med-brown1 mb-3" ] [ text "Your pharmacy" ]
        , case model.pharmacies of
            [] ->
                div [ class "border border-stone-300 rounded p-12 text-stone-400 text-center mt-4" ]
                    [ div [ class "font-medium" ] [ text "You have no pharmacy." ]
                    , div [ class "text-sm" ] [ text "Add your primary pharmacy by typing a zipcode into search box above." ]
                    ]

            primaryPharmacy :: alternatePharmacies ->
                div [ class "space-y-1" ]
                    (List.map
                        (\pharmacy ->
                            div [ class "border border-stone-200 rounded flex divide-x divide-stone-200" ]
                                [ div [ class "grow py-2 px-3" ]
                                    [ div [ class "mb-1 text-med-brown1" ] [ text pharmacy.name ]
                                    , div [ class "text-sm text-med-brown2" ] [ text (pharmacyAddressLine1 pharmacy) ]
                                    , div [ class "text-sm text-med-brown2" ] [ text (pharmacyAddressLine2 pharmacy) ]
                                    ]
                                , button [ class "flex items-center justify-center w-10 text-med-brown1", type_ "button", onClick (ClickedDeletePharmacy pharmacy.pharmacyId) ] [ Icon.default Icon.XClose ]
                                ]
                        )
                        (primaryPharmacy :: alternatePharmacies)
                    )
        ]

    -- Prev/Next
    , div [ class "flex" ]
        [ UI.viewSecondaryButton [ type_ "button", onClick (ClickedGoToPage Prescriptions) ]
            [ Icon.default Icon.ArrowLeft
            , span [] [ text "Prescriptions" ]
            ]
        , UI.viewPrimaryButton
            [ class "ml-auto"
            , type_ "button"
            , onClick (ClickedGoToPage AdditionalInfo)
            , disabled (List.isEmpty model.pharmacies)
            ]
            [ span [] [ text "Additional Info" ]
            , Icon.default Icon.ArrowRight
            ]
        ]
    ]


viewAdditionalProfilePage : Model -> List (Html Msg)
viewAdditionalProfilePage model =
    let
        validationErrors =
            validateAdditionalProfileForm model

        hasValidationError field =
            AnySet.member field model.validatingAddProfileFields && AnyDict.member field validationErrors

        validationError field =
            if AnySet.member field model.validatingAddProfileFields then
                AnyDict.get field validationErrors

            else
                Nothing

        firstFieldValidationError fields =
            List.map validationError fields
                |> List.filterMap identity
                |> List.head

        stateOptions =
            ( "", "" ) :: states
    in
    [ Html.form [ onSubmit SubmittedAdditionalProfileForm ]
        [ viewPageHeader
            { stepNum = 4
            , heading = "Additional Info"
            , caption = "Almost Done! Complete this info, and we’ll get your options ready for you to review."
            }
        , div [ class "mb-8 space-y-3" ]
            [ div [ class "space-y-[6px]" ]
                [ label [ class "block text-med-brown1" ] [ text "Date of birth" ]
                , div [ class "flex gap-3" ]
                    [ div []
                        [ label [ class "text-xs text-med-brown2 block", for "dob-month" ] [ text "Month" ]
                        , select
                            [ id "dob-month"
                            , onInput ChangedDateOfBirthMonth
                            , classList [ ( "input-error", hasValidationError DateOfBirthMonth ) ]
                            , autocomplete "bday-month"
                            ]
                            (option [] []
                                :: List.map
                                    (\( name, num ) ->
                                        option [ value num, selected (model.dateOfBirthMonth == num) ] [ text name ]
                                    )
                                    dateOfBirthMonths
                            )
                        ]
                    , div []
                        [ label [ class "text-xs text-med-brown2 block", for "dob-day" ] [ text "Day" ]
                        , select
                            [ id "dob-day"
                            , onInput ChangedDateOfBirthDate
                            , classList [ ( "input-error", hasValidationError DateOfBirthDay ) ]
                            , autocomplete "bday-day"
                            ]
                            (option [] []
                                :: List.map
                                    (\date ->
                                        option [ value date, selected (model.dateOfBirthDay == date) ] [ text date ]
                                    )
                                    dateOfBirthDays
                            )
                        ]
                    , div []
                        [ label [ class "text-xs text-med-brown2 block", for "dob-year" ] [ text "Year" ]
                        , select
                            [ id "dob-year"
                            , onInput ChangedDateOfBirthYear
                            , classList [ ( "input-error", hasValidationError DateOfBirthYear ) ]
                            , autocomplete "bday-year"
                            ]
                            (option [] []
                                :: List.map
                                    (\year ->
                                        option [ value year, selected (model.dateOfBirthYear == year) ] [ text year ]
                                    )
                                    dateOfBirthYears
                            )
                        ]
                    ]
                , case firstFieldValidationError [ DateOfBirthMonth, DateOfBirthDay, DateOfBirthYear ] of
                    Just errMsg ->
                        div [ class "text-xs text-red-500" ] [ text errMsg ]

                    Nothing ->
                        text ""
                ]
            , div [ class "space-y-[6px]" ]
                [ label [ for "address1", class "block text-med-brown1" ] [ text "Address" ]
                , input
                    [ id "address1"
                    , classList
                        [ ( "w-full", True )
                        , ( "input-error", hasValidationError Address1 )
                        ]
                    , type_ "text"
                    , value model.address1
                    , autocomplete "address-line1"
                    , onInput ChangedAddress1
                    ]
                    []
                , case validationError Address1 of
                    Just errMsg ->
                        div [ class "text-xs text-red-500" ] [ text errMsg ]

                    Nothing ->
                        text ""
                ]
            , div [ class "space-y-[6px]" ]
                [ label [ for "address2", class "block text-med-brown1" ] [ text "Address 2" ]
                , input
                    [ id "address2"
                    , class "w-full"
                    , type_ "text"
                    , value model.address2
                    , autocomplete "address-line2"
                    , onInput ChangedAddress2
                    ]
                    []
                ]
            , div [ class "space-y-[6px]" ]
                [ label [ for "city", class "block text-med-brown1" ] [ text "City" ]
                , input
                    [ id "city"
                    , classList
                        [ ( "w-full", True )
                        , ( "input-error", hasValidationError City )
                        ]
                    , type_ "text"
                    , value model.city
                    , autocomplete "address-level2"
                    , onInput ChangedCity
                    ]
                    []
                , case validationError City of
                    Just errMsg ->
                        div [ class "text-xs text-red-500" ] [ text errMsg ]

                    Nothing ->
                        text ""
                ]
            , div [ class "space-y-[6px]" ]
                [ label [ for "state", class "block text-med-brown1" ] [ text "State" ]
                , select
                    [ onInput ChangedState
                    , classList
                        [ ( "w-full", True )
                        , ( "input-error", hasValidationError State )
                        ]
                    , autocomplete "address-level1"
                    ]
                    (List.map
                        (\( name, abrv ) ->
                            option [ value abrv, selected (model.stateCode == abrv) ] [ text name ]
                        )
                        stateOptions
                    )
                , case validationError State of
                    Just errMsg ->
                        div [ class "text-xs text-red-500" ] [ text errMsg ]

                    Nothing ->
                        text ""
                ]
            ]

        -- Prev/Next
        , div [ class "flex" ]
            [ UI.viewSecondaryButton [ type_ "button", onClick (ClickedGoToPage Pharmacies) ]
                [ Icon.default Icon.ArrowLeft
                , span [] [ text "Pharmacies" ]
                ]
            , button
                [ type_ "submit"
                , classList
                    [ ( "ml-auto rounded bg-med-green text-white font-raleway font-semibold flex items-center gap-2 py-2 px-4 transition", True )
                    , ( "opacity-50", not (isAdditionalProfileFormValid model) )
                    , ( "hover:bg-[#009984]", isAdditionalProfileFormValid model )
                    ]
                ]
                [ span [] [ text "Summary" ]
                , Icon.default Icon.ArrowRight
                ]
            ]
        ]
    ]


viewSummaryPage : Model -> List (Html Msg)
viewSummaryPage model =
    let
        fullMonthName numStr =
            case numStr of
                "01" ->
                    "January"

                "02" ->
                    "February"

                "03" ->
                    "March"

                "04" ->
                    "April"

                "05" ->
                    "May"

                "06" ->
                    "June"

                "07" ->
                    "July"

                "08" ->
                    "August"

                "09" ->
                    "September"

                "10" ->
                    "October"

                "11" ->
                    "November"

                "12" ->
                    "December"

                _ ->
                    ""
    in
    case model.submission of
        RemoteData.Success _ ->
            viewThankYouPage

        _ ->
            [ div [ class "space-y-8" ]
                [ viewPageHeader
                    { stepNum = 5
                    , heading = "Summary"
                    , caption = "Review your information and submit. Something not right? Feel free to go back and make changes."
                    }
                , div []
                    [ div [ class "font-domine font-bold text-med-brown1 mb-4" ] [ text "Profile Information" ]
                    , div [ class "space-y-4 font-raleway" ]
                        [ div []
                            [ div [ class "text-xs text-med-brown2" ] [ text "Name" ]
                            , div [ class "text-med-brown1" ] [ text (model.firstName ++ " " ++ model.lastName) ]
                            ]
                        , div []
                            [ div [ class "text-xs text-med-brown2" ] [ text "Address" ]
                            , div [ class "text-med-brown1" ] [ text model.address1 ]
                            , if not (String.isEmpty model.address2) then
                                div [ class "text-med-brown1" ] [ text model.address2 ]

                              else
                                text ""
                            , div [ class "text-med-brown1" ] [ text (model.city ++ ", " ++ model.stateCode) ]
                            , div [ class "text-med-brown1" ] [ text model.zipCode ]
                            ]
                        , div []
                            [ div [ class "text-xs text-med-brown2" ] [ text "Date of birth" ]
                            , div [ class "text-med-brown1" ] [ text (fullMonthName model.dateOfBirthMonth ++ " " ++ model.dateOfBirthDay ++ ", " ++ model.dateOfBirthYear) ]
                            ]
                        , div []
                            [ div [ class "text-xs text-med-brown2" ] [ text "Phone number" ]
                            , div [ class "text-med-brown1" ]
                                [ span [] [ text model.phoneNumber ]
                                , if not (String.isEmpty model.phoneExtension) then
                                    span [] [ text (" X" ++ model.phoneExtension) ]

                                  else
                                    text ""
                                ]
                            ]
                        , div []
                            [ div [ class "text-xs text-med-brown2" ] [ text "Email address" ]
                            , div [ class "text-med-brown1" ] [ text model.emailAddress ]
                            ]
                        ]
                    ]
                , div []
                    [ div [ class "font-domine font-bold text-med-brown1 mb-4" ] [ text "Prescriptions" ]
                    , div [ class "space-y-4 font-raleway" ]
                        (List.map
                            (\p ->
                                div []
                                    [ div [ class "text-med-brown1" ] [ text (Prescription.drugName p) ]
                                    , div [ class "text-med-brown2" ] [ text (Prescription.toQuantityAndFrequency p) ]
                                    ]
                            )
                            model.prescriptions
                        )
                    ]
                , div []
                    [ div [ class "font-domine font-bold text-med-brown1 mb-4" ] [ text "Pharmacy" ]
                    , div [ class "space-y-4 font-raleway" ]
                        (List.indexedMap
                            (\idx p ->
                                div []
                                    [ div [ class "text-med-brown1" ] [ text p.name ]
                                    , div [ class "text-med-brown1" ] [ text (pharmacyAddressLine1 p) ]
                                    , div [ class "text-med-brown1" ] [ text (pharmacyAddressLine2 p) ]
                                    ]
                            )
                            model.pharmacies
                        )
                    ]
                , case model.submission of
                    RemoteData.Failure e ->
                        div [ class "bg-red-100 font-raleway text-red-800 text-center rounded p-2" ]
                            [ text
                                (case e of
                                    Server.BadUrl _ ->
                                        "Whoops! Something went wrong. We’ve been notified about the error. Please try again later."

                                    Server.Timeout ->
                                        "Looks like the server is taking to long to respond. This can be caused by poor connectivity or an error with our servers. Please try again later."

                                    Server.NetworkError ->
                                        "Unable to contact the server. Please check your internet connection."

                                    Server.BadStatus _ _ ->
                                        "Whoops! Something went wrong. We’ve been notified about the error. Please try again later."

                                    Server.BadBody _ ->
                                        "Whoops! Something went wrong. We’ve been notified about the error. Please try again later."
                                )
                            ]

                    _ ->
                        text ""

                -- Prev/Next
                , div [ class "flex" ]
                    [ UI.viewSecondaryButton [ type_ "button", onClick (ClickedGoToPage AdditionalInfo) ]
                        [ Icon.default Icon.ArrowLeft
                        , span [] [ text "Additional Info" ]
                        ]
                    , UI.viewPrimaryButton
                        [ class "ml-auto"
                        , type_ "button"
                        , onClick ClickedSubmit
                        ]
                        [ div [] [ text "Submit" ]
                        , case model.submission of
                            RemoteData.Loading ->
                                Icon.default Icon.Loading4Animated

                            _ ->
                                text ""
                        ]
                    ]
                ]
            ]


viewThankYouPage : List (Html msg)
viewThankYouPage =
    [ div [ class "text-med-green bg-[#E9F7F5] rounded-full flex items-center justify-center h-[176px] w-[176px] mx-auto mb-4 mt-5 sm:mt-0" ]
        [ Icon.new Icon.CheckCircleBroken
            |> Icon.withSize 88
            |> Icon.view
        ]
    , div [ class "text-3xl font-domine font-bold text-med-brown1 text-center" ] [ text "Thank you" ]
    , div [ class "font-raleway text-med-brown2 text-center" ] [ text "Your submission has been received." ]
    ]


viewNotFoundPage : List (Html msg)
viewNotFoundPage =
    [ div [ class "text-[#FFBF63] bg-[#FFF1DD] rounded-full flex items-center justify-center h-[176px] w-[176px] mx-auto mb-4 mt-5 sm:mt-0" ]
        [ Icon.new Icon.AlertTriangle
            |> Icon.withSize 88
            |> Icon.view
        ]
    , div [ class "text-3xl font-domine font-bold text-med-brown1 text-center" ] [ text "Page not found" ]
    ]


viewPageHeader : { stepNum : Int, heading : String, caption : String } -> Html msg
viewPageHeader { stepNum, heading, caption } =
    let
        stepText =
            "Step " ++ String.fromInt stepNum ++ " of 5"
    in
    div [ class "mb-8" ]
        [ div [ class "font-raleway text-[13px] text-med-brown2" ] [ text stepText ]
        , h1 [ class "font-domine font-bold text-med-brown1 text-3xl" ] [ text heading ]
        , div [ class "font-raleway text-med-brown2" ] [ text caption ]
        ]


viewNothing : Html Msg
viewNothing =
    text ""



-- SUBSCRIPTIONS


subscriptions : Model -> Sub Msg
subscriptions model =
    if model.drugNameSearchHasFocus || model.pharmacySearchHasFocus then
        Browser.Events.onClick (Decode.succeed ClickedDocument)

    else
        Sub.none



-- DRUG SEARCH


getDrugNameLeadingChars : String -> String
getDrugNameLeadingChars drugName =
    drugName
        |> String.trim
        |> String.left drugNameLeadingCharsLen



-- FORM VALIDATION


profileFieldToInt : ProfileField -> Int
profileFieldToInt field =
    case field of
        FirstName ->
            0

        LastName ->
            1

        EmailAddress ->
            2

        PhoneNumber ->
            3

        ZipCode ->
            4

        CountyCode ->
            5

        PhoneExtension ->
            6


validateProfileForm : Model -> AnyDict Int ProfileField String
validateProfileForm model =
    let
        validateFirstName errors =
            if Validate.isBlank model.firstName then
                AnyDict.insert FirstName "Required" errors

            else
                errors

        validateLastName errors =
            if Validate.isBlank model.lastName then
                AnyDict.insert LastName "Required" errors

            else
                errors

        validateEmailAddress errors =
            if Validate.isBlank model.emailAddress then
                AnyDict.insert EmailAddress "Required" errors

            else if not (Validate.isEmail model.emailAddress) then
                AnyDict.insert EmailAddress "Invalid email" errors

            else
                errors

        validatePhoneNumber errors =
            if Validate.isBlank model.phoneNumber then
                AnyDict.insert PhoneNumber "Required" errors

            else if not (Validate.isPhoneNumber model.phoneNumber) then
                AnyDict.insert PhoneNumber "Must contain 10 digits" errors

            else
                errors

        validatePhoneExtension errors =
            if Validate.isBlank model.phoneExtension then
                errors

            else if not (Validate.isNumeric model.phoneExtension) then
                AnyDict.insert PhoneExtension "Must be a number" errors

            else
                errors

        validateZipCode errors =
            if Validate.isBlank model.zipCode then
                AnyDict.insert ZipCode "Required" errors

            else if not (Validate.isZipCode model.zipCode) then
                AnyDict.insert ZipCode "Invalid zip code" errors

            else
                case model.counties of
                    RemoteData.Success [] ->
                        AnyDict.insert ZipCode "Zip code not found" errors

                    _ ->
                        errors

        validateCountyCode errors =
            if Validate.isBlank model.countyFipsCode then
                AnyDict.insert CountyCode "Required" errors

            else
                errors
    in
    AnyDict.empty profileFieldToInt
        |> validateFirstName
        |> validateLastName
        |> validateEmailAddress
        |> validatePhoneNumber
        |> validatePhoneExtension
        |> validateZipCode
        |> validateCountyCode


isProfileFormValid : Model -> Bool
isProfileFormValid model =
    AnyDict.isEmpty (validateProfileForm model)


additionalProfileFieldToInt : AdditionalProfileField -> Int
additionalProfileFieldToInt field =
    case field of
        DateOfBirthMonth ->
            0

        DateOfBirthDay ->
            1

        DateOfBirthYear ->
            2

        Address1 ->
            3

        Address2 ->
            4

        City ->
            5

        State ->
            6


validateAdditionalProfileForm : Model -> AnyDict Int AdditionalProfileField String
validateAdditionalProfileForm model =
    let
        validateDateOfBirthMonth errors =
            if Validate.isBlank model.dateOfBirthMonth then
                AnyDict.insert DateOfBirthMonth "Month is required" errors

            else if List.map Tuple.second dateOfBirthMonths |> List.member model.dateOfBirthMonth |> not then
                AnyDict.insert DateOfBirthMonth "Month is invalid" errors

            else
                errors

        validateDateOfBirthDate errors =
            if Validate.isBlank model.dateOfBirthDay then
                AnyDict.insert DateOfBirthDay "Date is required" errors

            else if List.member model.dateOfBirthDay dateOfBirthDays |> not then
                AnyDict.insert DateOfBirthDay "Date is invalid" errors

            else
                errors

        validateDateOfBirthYear errors =
            if Validate.isBlank model.dateOfBirthYear then
                AnyDict.insert DateOfBirthYear "Year is required" errors

            else if List.member model.dateOfBirthYear dateOfBirthYears |> not then
                AnyDict.insert DateOfBirthYear "Year is invalid" errors

            else
                errors

        validateAddress1 errors =
            if Validate.isBlank model.address1 then
                AnyDict.insert Address1 "Required" errors

            else
                errors

        validateCity errors =
            if Validate.isBlank model.city then
                AnyDict.insert City "Required" errors

            else
                errors

        validateState errors =
            if Validate.isBlank model.stateCode then
                AnyDict.insert State "Required" errors

            else if List.map Tuple.second states |> List.member model.stateCode |> not then
                AnyDict.insert State "Invalid state" errors

            else
                errors
    in
    AnyDict.empty additionalProfileFieldToInt
        |> validateDateOfBirthMonth
        |> validateDateOfBirthDate
        |> validateDateOfBirthYear
        |> validateAddress1
        |> validateCity
        |> validateState


isAdditionalProfileFormValid : Model -> Bool
isAdditionalProfileFormValid model =
    AnyDict.isEmpty (validateAdditionalProfileForm model)



-- UTILS


pharmacyAddress : Server.Pharmacy -> String
pharmacyAddress p =
    [ p.address1, p.address2, p.city, p.state, p.zip ]
        |> List.filter (not << String.isEmpty)
        |> String.join ", "


pharmacyAddressLine1 : Server.Pharmacy -> String
pharmacyAddressLine1 p =
    [ p.address1, p.address2 ]
        |> List.filter (not << String.isEmpty)
        |> String.join ", "


pharmacyAddressLine2 : Server.Pharmacy -> String
pharmacyAddressLine2 p =
    [ p.city, p.state, p.zip ]
        |> List.filter (not << String.isEmpty)
        |> String.join ", "


autocomplete : String -> Html.Attribute Msg
autocomplete tokens =
    Html.Attributes.attribute "autocomplete" tokens


hasRequiredQueryParams : Model -> Bool
hasRequiredQueryParams model =
    let
        hasAgentFirstName =
            case model.agentFirstName of
                Just agentFirstName ->
                    Validate.isBlank agentFirstName |> not

                Nothing ->
                    False

        hasAgentLastName =
            case model.agentLastName of
                Just agentLastName ->
                    Validate.isBlank agentLastName |> not

                Nothing ->
                    False

        hasUserId =
            case model.userId of
                Just userId ->
                    userId > 0

                Nothing ->
                    False
    in
    hasAgentFirstName && hasAgentLastName && hasUserId
