import { Elm } from "./Main.elm";
var app = Elm.Main.init({});

app.ports.pushStepChanged.subscribe(function (data) {
  if (window.APP.GOOGLE_TAG_MANAGER_ENABLED) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "intake_form_step",
      step: data.step,
      prevStep: data.prevStep,
      userId: data.userId,
    });
  }
});
